import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.includes.js";
import { storeToRefs } from 'pinia';
import { Fragment } from 'vue-fragment';
import SkrImage from '@/components/SkrImage.vue';
import useDocumentUrl from '@/composables/useDocumentUrl';
import { useDocumentStore } from '@/stores/document';
import { useInvitationStore } from '@/stores/invitation';
export default defineComponent({
  components: {
    Fragment: Fragment,
    SkrImage: SkrImage
  },
  props: {
    signatureRequest: {
      type: Object,
      required: true
    },
    hideDownloadButton: {
      type: Boolean,
      default: false
    },
    userCanSign: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _storeToRefs = storeToRefs(useDocumentStore()),
      activeDocument = _storeToRefs.activeDocument;
    var invitationStore = useInvitationStore();
    var _storeToRefs2 = storeToRefs(invitationStore),
      invitationSignatureRequests = _storeToRefs2.invitationSignatureRequests;
    var _useDocumentUrl = useDocumentUrl(),
      documentPagePreviewURL = _useDocumentUrl.documentPagePreviewURL;
    var redirectInfo = computed(function () {
      return {
        name: 'view-srid',
        params: {
          srid: props.signatureRequest.id
        }
      };
    });
    onBeforeUnmount(function () {
      invitationStore.removeDraft(props.signatureRequest.id);
      invitationSignatureRequests.value = [];
    });
    return {
      activeDocument: activeDocument,
      redirectInfo: redirectInfo,
      documentPagePreviewURL: documentPagePreviewURL
    };
  },
  methods: {
    signerDisplayString: function signerDisplayString(signature) {
      var _signature$signerIden;
      var displayString = '';

      // add name if it is known, i.e. user has an account or is a NAS
      if (Object.keys((_signature$signerIden = signature.signerIdentityData) !== null && _signature$signerIden !== void 0 ? _signature$signerIden : '').length > 0) {
        var _signature$signerIden2, _signature$signerIden3, _signature$signerIden5;
        // Use first_ and last_name if they are known or email if it was provided (SES directSign GUI).
        if ((_signature$signerIden2 = signature.signerIdentityData) !== null && _signature$signerIden2 !== void 0 && _signature$signerIden2.firstName || (_signature$signerIden3 = signature.signerIdentityData) !== null && _signature$signerIden3 !== void 0 && _signature$signerIden3.lastName) {
          var _signature$signerIden4;
          displayString = "".concat(signature.signerIdentityData.firstName, " ").concat((_signature$signerIden4 = signature.signerIdentityData) === null || _signature$signerIden4 === void 0 ? void 0 : _signature$signerIden4.lastName);
        } else if ((_signature$signerIden5 = signature.signerIdentityData) !== null && _signature$signerIden5 !== void 0 && _signature$signerIden5.emailAddress) {
          var _signature$signerIden6;
          displayString = (_signature$signerIden6 = signature.signerIdentityData) === null || _signature$signerIden6 === void 0 ? void 0 : _signature$signerIden6.emailAddress;
        }
      } else if (signature.accountEmail.includes('@')) {
        displayString = signature.accountEmail;
      }
      return displayString;
    }
  }
});