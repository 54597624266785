import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { storeToRefs } from 'pinia';
import Heading from '@/components/Heading.vue';
import SignatureRequestSettings from '@/components/SignatureRequestSettings.vue';
import useUserPlanInfo from '@/composables/useUserPlanInfo';
import { useBusinessStore } from '@/stores/business';
import { useUserStore } from '@/stores/user';
export default defineComponent({
  name: 'SetLegalWeight',
  components: {
    Heading: Heading,
    SignatureRequestSettings: SignatureRequestSettings
  },
  props: {
    signatureRequestInvitationDraft: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useUserStore()),
      email = _storeToRefs.email,
      qualityOverrides = _storeToRefs.disabledQualities;
    var _storeToRefs2 = storeToRefs(useBusinessStore()),
      companyHasPart11 = _storeToRefs2.companyHasPart11;
    var _useUserPlanInfo = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo.activePricePlanId;
    var isIndividualPlan = computed(function () {
      var _activePricePlanId$va;
      return (_activePricePlanId$va = activePricePlanId.value) === null || _activePricePlanId$va === void 0 ? void 0 : _activePricePlanId$va.startsWith('INDIVIDUAL');
    });
    var isV3 = computed(function () {
      var _activePricePlanId$va2;
      return (_activePricePlanId$va2 = activePricePlanId.value) === null || _activePricePlanId$va2 === void 0 ? void 0 : _activePricePlanId$va2.endsWith('V3');
    });
    var disabledQualities = computed(function () {
      var qualities = new Set(qualityOverrides.value);
      if (isIndividualPlan.value && isV3.value) {
        qualities.add('aes');
        qualities.add('qes');
      }
      return Array.from(qualities);
    });
    return {
      email: email,
      isIndividualPlan: isIndividualPlan,
      disabledQualities: disabledQualities,
      companyHasPart11: companyHasPart11
    };
  },
  data: function data() {
    return {
      settings: {
        legislation: this.signatureRequestInvitationDraft.signatureRequest.legislation || 'zertes',
        quality: this.signatureRequestInvitationDraft.signatureRequest.quality || 'ses'
      },
      singleSigner: this.signatureRequestInvitationDraft.options.singleSigner || 0
    };
  },
  watch: {
    singleSigner: function singleSigner(value) {
      var draftCopy = Object.assign({}, this.signatureRequestInvitationDraft);
      draftCopy.options.singleSigner = value;
      // TODO: notify user if other mails were already set and will be deleted now on the draft
      // in case if the user moved a step back and want to change the toggle
      if (value === 0) {
        draftCopy.signatureRequest.ccEmailAddresses = [];
        draftCopy.signatureRequest.signatures = [{
          accountEmail: this.email
        }];
      }
      if (value === 1) {
        draftCopy.options.userIsSigning = false;
        draftCopy.signatureRequest.signatures = [{
          accountEmail: ''
        }];
      }
      this.$emit('updateSignatureRequestDraft', {
        signatureRequestDraft: draftCopy
      });
    },
    settings: {
      deep: true,
      handler: function handler(value) {
        var draftCopy = Object.assign({}, this.signatureRequestInvitationDraft);
        draftCopy.signatureRequest.legislation = value.legislation;
        draftCopy.signatureRequest.quality = value.quality;
        this.$emit('updateSignatureRequestDraft', {
          signatureRequestDraft: draftCopy
        });
      }
    }
  },
  created: function created() {
    var _this = this;
    // To disable 'ses', 'aes', or 'qes':
    if (this.disabledQualities.includes(this.settings.quality)) {
      // The selected quality is disabled by the current user's business. Use
      // next higher quality if applicable, otherwise fall back to SES.
      var orderedFallbackQualities = ['aes', 'qes'];
      this.settings.quality = orderedFallbackQualities.find(function (quality) {
        return !_this.disabledQualities.includes(quality);
      }) || 'ses';
    }

    /**
     * If no legislation is disabled we use zertes as default.
     *
     * Special case:
     * If there is a disabled quality like 'zertes-eidas' and the default
     * legislation is set to eidas for this user, we need so switch it to
     * zertes and vice versa.
     */
    if (this.disabledQualities.some(function (quality) {
      return quality.includes(_this.settings.legislation);
    })) {
      if (this.disabledQualities.includes('qes-zertes')) {
        this.settings.legislation = 'eidas';
      } else if (this.disabledQualities.includes('qes-eidas')) {
        this.settings.legislation = 'zertes';
      }
    }
  }
});