<template>
  <div class="ongoing__uploads my-4">
    <div v-for="upload in ongoingUploads" :key="upload.id" class="d-flex align-center my-2">
      <v-progress-linear
        class="mr-3"
        color="primary"
        background-color="grey-fine"
        rounded
        height="8"
        :value="upload.percentage"
      />
      <div
        class="text-right progress-bar"
        :class="{
          'primary--text font-weight-bold': upload.percentage === 100,
        }"
      >
        {{ upload.percentage }}%
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

interface Props {
  ongoingUploads: { id: string; percentage: number }[]
}

export default Vue.extend<void, void, void, Props>({
  props: {
    ongoingUploads: {
      type: Array,
      default: () => [],
    },
  },
})
</script>

<style lang="sass">

$ns: ongoing__uploads

.#{$ns}
  .progress-bar
    min-width: 40px
    font-size: 0.875rem
</style>
