import "core-js/modules/es.number.constructor.js";
import { Fragment } from 'vue-fragment';
export default defineComponent({
  components: {
    Fragment: Fragment
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    isFormValid: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    }
  },
  inject: {
    isPart11RequirementMet: {
      default: false
    }
  },
  computed: {
    showBackButton: function showBackButton() {
      return this.currentStep < this.totalSteps && this.currentStep > 1;
    }
  }
});