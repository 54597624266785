import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Uppy from '@uppy/core';
import DragDrop from '@uppy/drag-drop';
import ProgressBar from '@uppy/progress-bar';
import XHRUpload from '@uppy/xhr-upload';
import { objectToCamelCase } from '@/composables/useCaseConvert';
import { useAuthStore } from '@/stores/auth';
export default defineComponent({
  name: 'UppyUploader',
  props: {
    uppySource: {
      type: String,
      default: 'dragAndDrop'
    },
    allowedFileTypes: {
      type: Array,
      default: null
    },
    maxSimultaneousUploads: {
      type: Number,
      default: null
    },
    maxFileSizeMB: {
      type: Number,
      default: 40
    },
    maxTotalFileSizeMB: {
      type: Number,
      default: null
    },
    endpoint: {
      type: String,
      default: ''
    }
  },
  setup: function setup() {
    var authStore = useAuthStore();
    return {
      getStoredToken: authStore.getStoredToken
    };
  },
  data: function data() {
    return {
      ongoingUpload: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    // Defines an Uppy Uploader instance
    var uppyUploader = new Uppy({
      restrictions: {
        maxFileSize: this.maxFileSizeMB * 1024 * 1024,
        // MB to B
        maxTotalFileSize: this.maxTotalFileSizeMB ? this.maxTotalFileSizeMB * 1024 * 1024 : null,
        // MB to B
        maxNumberOfFiles: this.maxSimultaneousUploads,
        allowedFileTypes: this.allowedFileTypes
      },
      autoProceed: true
    });

    // Set up the Uppy Source
    if (this.uppySource === 'fileInput') {
      // Use Uppy File input
      var fileInput = document.querySelector('#my-file-input');
      if (fileInput) {
        fileInput.addEventListener('change', function (event) {
          var target = event.target;
          var fileList = target.files;
          if (fileList) {
            var fileArray = Array.from(fileList);
            uppyUploader.cancelAll();
            fileArray.forEach(function (file) {
              uppyUploader.addFile({
                source: 'file input',
                name: file.name,
                type: file.type,
                data: file
              });
            });
          }
        });
        uppyUploader.on('complete', function () {
          fileInput.value = '';
        });
      }
    } else {
      // Use Uppy Drag and Drop input as default and fallback
      uppyUploader.use(DragDrop, {
        target: '.drag-and-drop',
        locale: {
          strings: {
            dropHereOr: this.$t('signature_request.upload.cta_drop_uppyText'),
            browse: this.$t('signature_request.upload.cta_drop_uppyLink')
          }
        },
        note: this.$t('signature_request.upload.file_size_tip', {
          n: this.maxFileSizeMB
        })
      });

      // Use Uppy Progress Bar
      uppyUploader.use(ProgressBar, {
        target: '.progress-bar'
      });

      // Prevent new files from being added after upload has already started
      uppyUploader.on('file-added', function () {
        document.getElementsByClassName('uppy-DragDrop-container')[0].setAttribute('style', 'pointer-events: none');

        // Prevent users mistakenly trying to drag and drop a file during an existing upload and the file hijacking the browser tab
        window.addEventListener('dragover', function (e) {
          e = e || event;
          e.preventDefault();
        }, false);
        window.addEventListener('drop', function (e) {
          e = e || event;
          e.preventDefault();
        }, false);
      });
    }
    uppyUploader.on('file-added', function (file) {
      _this.ongoingUpload = true;
      var fileProgress = {
        id: file.id,
        percentage: 0
      };
      _this.$emit('onFileAdded', fileProgress);
    });
    var token = this.getStoredToken();

    // Use Uppy XHRUpload
    // These settings setup uppy to produce a similar POST request to the former el-upload, and therefore be accepted by Phoebe
    uppyUploader.use(XHRUpload, {
      fieldName: 'file',
      bundle: false,
      formData: true,
      endpoint: "".concat(this.$http.getBaseURL(), "/v1/").concat(this.endpoint),
      limit: 1,
      headers: {
        authorization: "bearer ".concat(token)
      },
      getResponseData: function getResponseData(responseText) {
        var parsedData = JSON.parse(responseText);
        return objectToCamelCase(parsedData);
      }
    });

    // Handle upload errors detected by Uppy on the Front End
    uppyUploader.on('restriction-failed', function (_, error) {
      // Error Feedback: Wrong File Type
      if (error.message.includes('You can only upload:')) {
        var _this$allowedFileType;
        var allowedFileTypesString = (_this$allowedFileType = _this.allowedFileTypes) === null || _this$allowedFileType === void 0 ? void 0 : _this$allowedFileType.map(function (type) {
          return type.substring(type.length - 3).toUpperCase();
        }) // Clean up file type names (i.e. '.pdf' -> 'PDF')
        .join(', '); // and join them, comma separated

        void _this.$store.dispatch('setSnackbar', {
          message: {
            key: 'upload.error.file_type_error',
            data: {
              type: allowedFileTypesString
            }
          }
        });
      } else if (
      // Error Feedback: Max individual file size exceeded
      error.message.includes("exceeds maximum allowed size of ".concat(_this.maxFileSizeMB))) {
        void _this.$store.dispatch('setSnackbar', {
          message: {
            key: 'upload.error.file_size_error',
            data: {
              n: _this.maxFileSizeMB
            }
          }
        });
      } else if (
      // Error Feedback: Max total files size exceeded
      error.message.includes("exceeds maximum allowed size of ".concat(_this.maxTotalFileSizeMB))) {
        void _this.$store.dispatch('setSnackbar', {
          message: {
            key: 'upload.error.total_files_size_error',
            data: {
              n: _this.maxTotalFileSizeMB
            }
          }
        });
      } else if (error.message.includes('You can only upload ')) {
        // Error Feedback: Too many files
        void _this.$store.dispatch('setSnackbar', {
          message: {
            key: 'upload.error.limit_error',
            data: {
              n: _this.maxSimultaneousUploads
            }
          }
        });
      } else if (error.message.includes('Cannot add the duplicate file')) {
        // Error Feedback: Too many files
        void _this.$store.dispatch('setSnackbar', {
          message: {
            key: 'upload.error.already_uploaded'
          }
        });
      }
    });

    // Communicate upload progress
    uppyUploader.on('upload-progress', function (file, progress) {
      if (!file) return;
      var fileProgress = {
        id: file.id,
        percentage: Math.round(progress.bytesUploaded / progress.bytesTotal * 100)
      };
      _this.$emit('onUploadProgress', fileProgress);
    });

    // Handle upload errors communicated by the Back End
    uppyUploader.on('upload-error', function (file, _0, response) {
      var _response$body;
      // Reset progress bar which stayed at 100% when Front End succeeds Upload but Back End returns error
      uppyUploader.resetProgress();
      if (_this.uppySource === 'dragAndDrop') {
        document.getElementsByClassName('uppy-DragDrop-container')[0].setAttribute('style', 'pointer-events: auto');
      }
      var errorKey = (response === null || response === void 0 ? void 0 : (_response$body = response.body) === null || _response$body === void 0 ? void 0 : _response$body.key) || '';
      var attachmentName = (file === null || file === void 0 ? void 0 : file.name) || '';
      var snackbarMessage = _this.mapErrorKeyToSnackbarMessage(errorKey, attachmentName);
      void _this.$store.dispatch('setSnackbar', {
        message: snackbarMessage
      });
      _this.$emit('onUploadError', file === null || file === void 0 ? void 0 : file.id);
    });

    // Emit to parent component that upload has been done along with file list
    uppyUploader.on('complete', function (result) {
      var response = result.successful;
      _this.ongoingUpload = false;
      if (response.length > 0) {
        _this.$emit('onUploadsComplete', response);
      }
    });
  },
  methods: {
    mapErrorKeyToSnackbarMessage: function mapErrorKeyToSnackbarMessage(key, attachmentName) {
      var pdfErrors = {
        'file.pdf.general_error': 'upload.error.pdf_damaged.snackbar',
        'file.pdf.signature_verification.failed': 'upload.error.pdf_invalid_signatures.snackbar',
        'file.pdf.encryption.no_changes_allowed': 'upload.error.pdf_protected_by_author.snackbar',
        'file.pdf.encryption.unknown_password': 'upload.error.pdf_pwd_protected.snackbar',
        'file.pdf.encryption.unknown_permissions': 'upload.error.pdf_protected.snackbar',
        'file.pdf.malware.detected': 'upload.error.pdf_invalid.snackbar' // deprecated
      };
      var pdfMessage = pdfErrors[key];
      if (pdfMessage) {
        return {
          key: pdfMessage
        };
      }
      var attachmentErrors = {
        'file.malware.detected': 'upload.error.malware_detected.snackbar',
        'file.attachment.unsupported': 'upload.error.attachment_unsupported.snackbar'
      };
      var attachmentMessage = attachmentErrors[key];
      if (attachmentMessage) {
        return {
          key: attachmentMessage,
          data: {
            attachment: attachmentName
          }
        };
      }
      return {
        key: 'global.general_error'
      };
    }
  }
});