import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.starts-with.js";
import useUserPlanInfo from '@/composables/useUserPlanInfo';
export default defineComponent({
  props: {
    disabledQualities: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    hasPart11: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props, context) {
    var _useUserPlanInfo = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo.activePricePlanId;
    var isIndividualPlan = computed(function () {
      var _activePricePlanId$va;
      return (_activePricePlanId$va = activePricePlanId.value) === null || _activePricePlanId$va === void 0 ? void 0 : _activePricePlanId$va.startsWith('INDIVIDUAL');
    });
    var settings = computed({
      get: function get() {
        return props.value;
      },
      set: function set(settings) {
        context.emit('input', settings);
      }
    });
    var activeQualities = computed(function () {
      var qualities = ['ses', 'aes', 'qes'];
      if (props.hasPart11) {
        return qualities.concat('part11');
      } else {
        return qualities;
      }
    });
    return {
      isIndividualPlan: isIndividualPlan,
      settings: settings,
      activeQualities: activeQualities
    };
  },
  data: function data() {
    return {
      legislations: ['eidas', 'zertes'],
      menu: false
    };
  },
  computed: {
    dynamicTranslations: function dynamicTranslations() {
      return {
        label: {
          aes: this.$t('signature_request.invite.legal.aes.button.label'),
          qes: this.$t('signature_request.invite.legal.qes.button.label'),
          ses: this.$t('signature_request.invite.legal.ses.button.label'),
          part11: this.$t('signature_request.invite.legal.part11.button.label')
        },
        countryCode: {
          ch: this.$t('settings.signature_standard.legislation.ch_label'),
          eu: this.$t('settings.signature_standard.legislation.eu_label')
        }
      };
    }
  },
  methods: {
    isQualityDisabled: function isQualityDisabled(quality) {
      return this.disabledQualities.includes(quality);
    },
    qualitySelected: function qualitySelected(quality) {
      if (quality !== 'qes') {
        this.menu = false;
      }
    },
    countryCode: function countryCode(legislation) {
      if (legislation === 'eidas') return 'eu';
      if (legislation === 'zertes') return 'ch';
      return 'ch';
    }
  }
});