var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"itemElement",staticClass:"item",class:{
    'item--placeholder': _vm.placeholder,
    'item--placed': _setup.isPlaced,
    'item--draggable': _vm.draggable,
    'item--disabled': _setup.isDragging || _vm.disabled,
    'item--removable': _vm.removable,
    'item--zoomable': _setup.isZoomable,
  },attrs:{"draggable":_vm.draggable && !_setup.isLoading && !_setup.isZoomable}},[_c('v-fade-transition',{attrs:{"mode":"out-in","duration":150}},[(_setup.isLoading)?_c('svg',{staticClass:"item__skeleton",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":`0 0 ${_setup.itemWidth} ${_setup.itemHeight}`}},[_c('defs',[_c('linearGradient',{attrs:{"id":"seal-shimmer-gradient","x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{attrs:{"offset":"0%","stop-color":"rgba(255, 255, 255, 0)"}}),_vm._v(" "),_c('stop',{attrs:{"offset":"50%","stop-color":"rgba(255, 255, 255, 0.3)"}}),_vm._v(" "),_c('stop',{attrs:{"offset":"100%","stop-color":"rgba(255, 255, 255, 0)"}})],1)],1),_vm._v(" "),_c('rect',{staticClass:"bone",attrs:{"x":"0","y":"0","width":_setup.itemWidth,"height":_setup.itemHeight}}),_vm._v(" "),_c('rect',{staticClass:"shimmer",attrs:{"x":-_setup.itemWidth,"y":"0","width":_setup.itemWidth,"height":_setup.itemHeight}},[_c('animate',{attrs:{"attributeName":"x","from":-_setup.itemWidth,"to":_setup.itemWidth,"dur":"1.5s","repeatCount":"indefinite"}})])]):_c('img',{ref:"imageElement",attrs:{"src":_setup.srcUrl,"alt":_setup.alt},on:{"load":_setup.onImgLoaded}})]),_vm._v(" "),_c('v-fade-transition',{attrs:{"mode":"out-in","duration":150}},[(_vm.clustered)?_c('div'):(_setup.isZoomable && _setup.isLoading)?_c('div',{staticClass:"item__action item__action--zoom item__action--loading"},[_c(_setup.SpinnerSmall,{staticClass:"spinner"})],1):(_setup.isZoomable)?_c('div',{staticClass:"item__action item__action--zoom",on:{"click":function($event){_setup.itemElement && _setup.emit('zoom', _setup.itemElement)}}},[_c('v-icon',{attrs:{"size":"16","color":"white"}},[_vm._v("$vuetify.icons.zoom")])],1):(_vm.removable && !_setup.isDragging)?_c('div',{staticClass:"item__action item__action--remove",on:{"click":function($event){return _setup.emit('remove')}}},[_c('v-icon',{attrs:{"size":"13","color":"white"}},[_vm._v("$vuetify.icons.close_x")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }