import "core-js/modules/es.number.constructor.js";
import AppBarNavigationButtons from '@/components/pages/invite/AppBarNavigationButtons.vue';
export default defineComponent({
  name: 'InviteHeader',
  components: {
    AppBarNavigationButtons: AppBarNavigationButtons
  },
  props: {
    activeDocumentTitle: {
      type: String,
      required: false
    },
    currentStep: {
      type: Number,
      required: true
    },
    isFormValid: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    }
  }
});