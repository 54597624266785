import { render, staticRenderFns } from "./UppyUploader.vue?vue&type=template&id=cc4e693c"
import script from "./UppyUploader.vue?vue&type=script&lang=ts"
export * from "./UppyUploader.vue?vue&type=script&lang=ts"
import style0 from "./UppyUploader.vue?vue&type=style&index=0&id=cc4e693c&prod&lang=sass"
import style1 from "@uppy/core/dist/style.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@uppy/progress-bar/dist/style.min.css?vue&type=style&index=2&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports