<template>
  <div
    class="drag-indicator"
    :class="{
      'drag-indicator__invert': invert || (!invert && interact),
      'drag-indicator__compact': compact,
    }"
    @mousedown="interact = true"
    @mouseleave="interact = false"
  >
    <span v-show="!compact" />
    <span v-show="!compact" />
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'DragIndicator',
  props: {
    invert: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      interact: false,
    }
  },
})
</script>

<style scoped lang="sass">
.drag-indicator
  display: grid
  grid-template: "a b" 3px "c d" 3px "e f" 3px "g h" 3px "i j"
  grid-gap: 3px
  padding: 0.5rem 1rem
  +maw(sm)
    padding-left: 0.2rem
  border-top-left-radius: 4px
  border-bottom-left-radius: 4px
  cursor: pointer
  transition: background .36s cubic-bezier(.4,.0,.2,1)
  &__invert, &__invert:hover
    background: #006FE6
    span
      background: white !important
  &__compact
    grid-template: "a b" 3px "c d" 3px "e f" 3px "g h"
  span
    width: 3px
    height: 3px
    display: block
    border-radius: 50%
    background: $c-grey-dark-2
</style>
