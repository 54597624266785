import { storeToRefs } from 'pinia';
import Heading from '@/components/Heading.vue';
import InvitationSentSuccess from '@/components/InvitationSentSuccess.vue';
import { useDocumentStore } from '@/stores/document';
import { useInvitationStore } from '@/stores/invitation';
export default defineComponent({
  name: 'InvitationSent',
  components: {
    Heading: Heading,
    InvitationSentSuccess: InvitationSentSuccess
  },
  props: {
    userCanSign: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useDocumentStore()),
      activeDocument = _storeToRefs.activeDocument;
    var _storeToRefs2 = storeToRefs(useInvitationStore()),
      invitationSignatureRequests = _storeToRefs2.invitationSignatureRequests;
    var processedSignatureRequest = computed(function () {
      return invitationSignatureRequests.value[0];
    });
    return {
      activeDocument: activeDocument,
      processedSignatureRequest: processedSignatureRequest
    };
  }
});