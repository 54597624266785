import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Heading from '@/components/Heading.vue';
import OrderingBlock from '@/components/OrderingBlock.vue';
import useValidation from '@/composables/useValidation';
import { useUserStore } from '@/stores/user';
export default defineComponent({
  name: 'InviteSigners',
  components: {
    Heading: Heading,
    OrderingBlock: OrderingBlock
  },
  props: {
    signatureRequestInvitationDraft: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var userStore = useUserStore();
    var _useValidation = useValidation(),
      isEmail = _useValidation.isEmail,
      isEmptyString = _useValidation.isEmptyString;
    var userEmail = computed(function () {
      return userStore.email;
    });
    return {
      userEmail: userEmail,
      isEmail: isEmail,
      isEmptyString: isEmptyString
    };
  },
  data: function data() {
    return {
      signingOrderEnabled: false,
      userIsSigning: false,
      userFirstLastName: '',
      invitees: {},
      ordering: []
    };
  },
  watch: {
    invitees: {
      deep: true,
      handler: function handler() {
        this.onChangeInviteesOrOrder();
      }
    },
    ordering: function ordering() {
      this.onChangeInviteesOrOrder();
    },
    signingOrderEnabled: function signingOrderEnabled() {
      var draftCopy = Object.assign({}, this.signatureRequestInvitationDraft);
      draftCopy.options.signingOrderEnabled = this.signingOrderEnabled;
      this.$emit('updateSignatureRequestDraft', {
        signatureRequestDraft: draftCopy
      });
      if (!this.signingOrderEnabled) {
        this.ordering = this.ordering.reduce(function (order, group) {
          group.forEach(function (item) {
            return order.push([item]);
          });
          return order;
        }, []);
      } else {
        this.onChangeInviteesOrOrder();
      }
    }
  },
  mounted: function mounted() {
    this.initStep();
  },
  beforeDestroy: function beforeDestroy() {
    // to release the invalid form state:
    // - not signing && no invitees set
    // in case the user navigates a step back
    this.$emit('setStepValidity', true);
  },
  methods: {
    onChangeInviteesOrOrder: function onChangeInviteesOrOrder() {
      var _this = this;
      // Get emails from Record
      var newEmails = Object.values(this.invitees);

      // Load signature request draft
      var draftCopy = Object.assign({}, this.signatureRequestInvitationDraft);

      // Create signatures array
      var signatures;
      if (this.signingOrderEnabled) {
        // Use sequence field, and sort signatures accordingly
        signatures = Object.keys(this.invitees).map(function (id) {
          return {
            inviteeID: id,
            accountEmail: _this.invitees[id],
            sequence: _this.getOrderOfID(id)
          };
        }).sort(function (a, b) {
          return a.sequence - b.sequence;
        });
      } else {
        // Otherwise, return only the emails
        signatures = Object.keys(this.invitees).map(function (id) {
          return {
            inviteeID: id,
            accountEmail: _this.invitees[id]
          };
        });
      }
      draftCopy.signatureRequest.signatures = signatures;

      // Check if any of the emails corresponds to the current user email
      this.userIsSigning = newEmails.includes(this.userEmail);
      draftCopy.options.userIsSigning = this.userIsSigning;

      // Stores ordering information
      draftCopy.order.invitees = this.invitees;
      draftCopy.order.ordering = this.ordering;

      // Emit current signatureRequestDraft state
      this.$emit('updateSignatureRequestDraft', {
        signatureRequestDraft: draftCopy
      });

      // Check and emit step validity
      // Check if there is at least one valid email
      var hasOneOrMoreValidMail = newEmails.some(function (mail) {
        return _this.isEmail(mail);
      });

      // Check if there are no invalid emails
      var lastOrderIndex = this.ordering.length;
      var hasInvalidMail = Object.keys(this.invitees).some(function (id) {
        var orderIndex = _this.getOrderOfID(id);
        var email = _this.invitees[id];

        // Allow empty email field, if it's last on the list
        // otherwise, invalidate empty email fields
        if (orderIndex === lastOrderIndex) return !(_this.isEmail(email) || _this.isEmptyString(email));else return !_this.isEmail(email);
      });
      this.$emit('setStepValidity', hasOneOrMoreValidMail && !hasInvalidMail);
    },
    onChangeUserIsSigning: function onChangeUserIsSigning() {
      var _this2 = this;
      if (this.userIsSigning) {
        // Add an invitee with current user's email
        var userID = this.createId();
        this.$set(this.invitees, userID, this.userEmail);
        this.ordering.splice(0, 0, [userID]);
      } else {
        // Remove all instances of current user's email
        var selfUserIDs = Object.keys(this.invitees).filter(function (id) {
          return _this2.invitees[id] === _this2.userEmail;
        });
        selfUserIDs.forEach(function (userID) {
          return _this2.removeSigner(userID);
        });
      }
    },
    initStep: function initStep() {
      var _this$signatureReques, _this$signatureReques2, _this$signatureReques3, _this$signatureReques4, _this$signatureReques5, _this$signatureReques6, _this$signatureReques7, _this$signatureReques8;
      this.userIsSigning = ((_this$signatureReques = this.signatureRequestInvitationDraft) === null || _this$signatureReques === void 0 ? void 0 : (_this$signatureReques2 = _this$signatureReques.options) === null || _this$signatureReques2 === void 0 ? void 0 : _this$signatureReques2.userIsSigning) || false;
      this.signingOrderEnabled = ((_this$signatureReques3 = this.signatureRequestInvitationDraft) === null || _this$signatureReques3 === void 0 ? void 0 : (_this$signatureReques4 = _this$signatureReques3.options) === null || _this$signatureReques4 === void 0 ? void 0 : _this$signatureReques4.signingOrderEnabled) || false;
      this.invitees = ((_this$signatureReques5 = this.signatureRequestInvitationDraft) === null || _this$signatureReques5 === void 0 ? void 0 : (_this$signatureReques6 = _this$signatureReques5.order) === null || _this$signatureReques6 === void 0 ? void 0 : _this$signatureReques6.invitees) || {};
      this.ordering = ((_this$signatureReques7 = this.signatureRequestInvitationDraft) === null || _this$signatureReques7 === void 0 ? void 0 : (_this$signatureReques8 = _this$signatureReques7.order) === null || _this$signatureReques8 === void 0 ? void 0 : _this$signatureReques8.ordering) || [];
      var noInviteesExist = Object.keys(this.invitees).length === 0;
      if (noInviteesExist) this.addSigner();
    },
    createId: function createId() {
      return "".concat(Math.round(Math.random() * 10000000));
    },
    addSigner: function addSigner() {
      var email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var id = this.createId();
      this.$set(this.invitees, id, email);
      this.ordering.push([id]);
    },
    onChangeValue: function onChangeValue(_ref) {
      var value = _ref.value,
        id = _ref.id;
      if (this.invitees[id] !== undefined) this.$set(this.invitees, id, value);
    },
    onChangeOrder: function onChangeOrder(order) {
      this.ordering = order;
    },
    removeSigner: function removeSigner(itemID) {
      if (this.invitees[itemID] !== undefined) this.$delete(this.invitees, itemID);
      this.ordering = this.ordering.map(function (group) {
        return group.filter(function (id) {
          return id !== itemID;
        });
      }).filter(function (group) {
        return group.length > 0;
      });
      if (this.ordering.length === 1) this.ordering = this.ordering[0].map(function (item) {
        return [item];
      });
      var inviteesListIsEmpty = Object.keys(this.invitees).length === 0;
      if (inviteesListIsEmpty) this.addSigner();
    },
    getOrderOfID: function getOrderOfID(id) {
      var _this3 = this;
      var orderAsString = Object.keys(this.ordering).find(function (orderLevel) {
        return _this3.ordering[orderLevel].includes(id);
      });
      var orderAsNumber = parseInt(orderAsString !== null && orderAsString !== void 0 ? orderAsString : '');
      // This function is used to pass the order/sequence to the backend, where the starting value is 1
      return orderAsNumber + 1;
    }
  }
});